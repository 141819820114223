import moment from "moment";

export default {
    methods: {
        formatTimestamp: (timestamp) => {
            if (!timestamp) {
                return '-'
            }
            return moment.unix(timestamp).format('DD/MM/YYYY HH:mm:ss')
        },
        formatDate: (date) => {
            if (!date) {
                return '-'
            }
            return moment(date).format('DD/MM/YYYY')
        },
        formatDateTime: (date) => {
            if (!date) {
                return '-'
            }
            return moment(date).format('DD/MM/YYYY HH:mm:ss')
        },
        isDateInRange: (date, start, end) => {
            if (!start && !end) {
                return null;
            }
            if (!start) {
                return moment(date).isBefore(end)
            }
            if (!end) {
                return moment(date).isAfter(end)
            }

            return moment(date).isBetween(start, end)
        },
        isTimestampInDateRange: (timestamp, start, end) => {
            if (!start && !end) {
                return null;
            }
            if (!start) {
                return moment.unix(timestamp).isBefore(end)
            }
            if (!end) {
                return moment.unix(timestamp).isAfter(end)
            }

            return moment.unix(timestamp).isBetween(start, end)
        }
    }
}
